var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "编辑车辆组",
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            width: "500px",
            "show-close": false,
            "custom-class": "dialog",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticStyle: { padding: "0" },
              attrs: {
                model: _vm.formInline,
                rules: _vm.rules,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "车辆组名称", prop: "parkCarGroupName" } },
                [
                  _c("el-input", {
                    staticClass: "formWidth",
                    attrs: {
                      placeholder: "请填写车辆组名称",
                      disabled: _vm.disabled,
                      maxlength: "15",
                    },
                    model: {
                      value: _vm.formInline.parkCarGroupName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "parkCarGroupName", $$v)
                      },
                      expression: "formInline.parkCarGroupName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("searchModule.Vehicle_type"),
                    prop: "groupType",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择", disabled: true },
                      model: {
                        value: _vm.formInline.groupType,
                        callback: function ($$v) {
                          _vm.$set(_vm.formInline, "groupType", $$v)
                        },
                        expression: "formInline.groupType",
                      },
                    },
                    [
                      _c(
                        "el-option",
                        { attrs: { value: 1, label: "免费车" } },
                        [_vm._v("免费车")]
                      ),
                      _c(
                        "el-option",
                        { attrs: { value: 2, label: "临时车" } },
                        [_vm._v("临时车")]
                      ),
                      _c(
                        "el-option",
                        { attrs: { value: 3, label: "军警车" } },
                        [_vm._v("军警车")]
                      ),
                      _c(
                        "el-option",
                        {
                          attrs: { value: 4, label: "月租车", disabled: true },
                        },
                        [_vm._v("月租车")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: _vm.$t("searchModule.state"), prop: "state" },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择", disabled: _vm.disabled },
                      model: {
                        value: _vm.formInline.state,
                        callback: function ($$v) {
                          _vm.$set(_vm.formInline, "state", $$v)
                        },
                        expression: "formInline.state",
                      },
                    },
                    [
                      _c("el-option", { attrs: { value: 2, label: "禁用" } }, [
                        _vm._v("禁用"),
                      ]),
                      _c("el-option", { attrs: { value: 1, label: "启用" } }, [
                        _vm._v("启用"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("searchModule.remarks") } },
                [
                  _c("el-input", {
                    staticStyle: { width: "330px" },
                    attrs: {
                      type: "textarea",
                      placeholder: "",
                      "auto-complete": "off",
                      maxlength: "20",
                      rows: 4,
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.formInline.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "description", $$v)
                      },
                      expression: "formInline.description",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v(_vm._s(_vm.$t("button.preservation")))]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }