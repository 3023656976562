<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          ref="form"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('list.Vehicle_group')">
                <el-select
                  v-model="formInline.parkCarGroupId"
                  placeholder="请选择"
                >
                  <el-option value label="全部">全部</el-option>
                  <el-option
                    :label="value.parkCarGroupName"
                    :value="value.parkCarGroupId"
                    v-for="value in carGroup"
                    :key="value.code"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Vehicle_group_type')">
                <el-select v-model="formInline.groupType" placeholder="请选择">
                  <el-option label="全部" value>全部</el-option>
                  <el-option
                    :label="value.desc"
                    :value="value.code"
                    v-for="value in carGroupType"
                    :key="value.code"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.plate_number')">
                <el-autocomplete
                  valueKey="plateNumber"
                  class="inline-input"
                  v-model.trim="plateNumber"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="请输入内容"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Car_owner_name')">
                <el-input v-model="formInline.carOwner"></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.phone_number')">
                <el-input v-model="formInline.mobile"></el-input>
              </el-form-item>
              <el-form-item label="生效状态">
                <el-select
                  v-model="formInline.effectiveState"
                  placeholder="请选择"
                >
                  <el-option value label="全部">全部</el-option>
                  <el-option :value="1" label="未生效">未生效</el-option>
                  <el-option :value="2" label="生效中">生效中</el-option>
                  <el-option :value="3" label="已过期">已过期</el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.state')">
                <el-select v-model="formInline.state" placeholder="请选择">
                  <el-option value label="全部">全部</el-option>
                  <el-option :value="1" label="启用">启用</el-option>
                  <el-option :value="2" label="禁用">禁用</el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                :loading="loading"
                @click="
                  pageNum = 1;
                  searchData();
                "
                >{{ $t('button.search') }}</el-button
              >
            </div>
          </div>
          <div class="col_box_boder"></div>
          <div class="col_box h44">
            <div class="col_left">
              <el-button
                type="primary"
                v-if="authority.button.add"
                icon="el-icon-plus"
                @click="addBerth"
                >{{ $t('button.addto') }}</el-button
              >
              <el-button
                plain
                icon="el-icon-upload"
                @click="openMuli"
                v-if="authority.button.export"
                >批量{{ $t('button.import') }}</el-button
              >
            </div>
            <div class="col_right"></div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper">
        <el-table
          header-cell-class-name="header-call-style"
          v-loading="loading"
          :data="tableData"
        >
          <el-table-column
            type="index"
            :label="$t('list.index')"
            width="60"
            align="center"
          ></el-table-column>
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="syncStatus"
            label="同步状态"
            v-if="details.parkSystemType != 0 && details.parkAccessType != 3"
            align="center"
          >
            <template slot-scope="scope">
              <span :class="scope.row.syncStatus | colorFilter">{{
                scope.row.syncStatus | stateFilter
              }}</span>
            </template>
          </el-table-column>
          <!--<el-table-column label="操作" v-if="authority.button.view || authority.button.edit" align="center" width="80">-->
          <el-table-column
            label="操作"
            align="center"
            width="80"
            v-if="
              authority.button.enable ||
              authority.button.disable ||
              authority.button.edit ||
              authority.button.delete
            "
          >
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event, scope.row)">
                <el-button type="text" size="small" style="padding: 0"
                  >操作<i class="el-icon-arrow-down"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <!--			  <el-dropdown-item v-if="authority.button.view" command="a">查看</el-dropdown-item>
					  <el-dropdown-item v-if="authority.button.edit" command="b">编辑</el-dropdown-item>
                  <el-dropdown-item v-if="authority.button.delete" command="c">删除</el-dropdown-item>-->
                  <!-- <el-dropdown-item command="b" v-if="">编辑</el-dropdown-item>
                  <el-dropdown-item command="c" v-if="scope.row.parkCarGroupName != '月租车A'">删除</el-dropdown-item>-->

                  <el-dropdown-item
                    command="b"
                    v-if="
                      authority.button.edit &&
                      scope.row.parkCarGroupName != '月租车A'
                    "
                    >编辑</el-dropdown-item
                  >
                  <el-dropdown-item
                    command="c"
                    v-if="
                      authority.button.delete &&
                      scope.row.parkCarGroupName != '月租车A'
                    "
                    >删除</el-dropdown-item
                  >
                  <el-dropdown-item command="a" v-if="authority.button.enable"
                    >启用</el-dropdown-item
                  >
                  <el-dropdown-item command="d" v-if="authority.button.disable"
                    >禁用</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-top: 10px">
          <el-button
            type="primary"
            @click="sync"
            v-if="
              authority.button.sync &&
              details.parkSystemType != 0 &&
              details.parkAccessType != 3
            "
            >同步本地</el-button
          >
        </div>
        <!--添加-->
        <add-berth @searchData="init" ref="addBerth"></add-berth>
        <!--编辑-->
        <edit-berth @searchData="init" ref="editBerth"></edit-berth>
      </div>
      <upload-file
        ref="upload"
        @success="success"
        :uploadData="uploadData"
        :uploadUrl="uploadUrl"
        :modelUrl="modelUrl"
        itemName="车辆"
      ></upload-file>
      <!--分页器-->
      <div class="pagerWrapper">
        <div class="block">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import addBerth from "./AddCar";
import editBerth from "./EditCar";
import uploadFile from "@/components/uploadFileCommon/";
import { dateFormat } from "../../../../../common/js/public.js";
export default {
  props: ["authority", "details"],
  name: "CarList",
  // inject: ["parkId"],
  data() {
    return {
      parkId: this.$route.query.parkId,
      parkCarId: "",
      modelUrl: "/acb/2.0/bacb/parkCar/downloadExcelTemplate",
      uploadData: { parkId: this.parkId },
      uploadUrl: "/acb/2.0/bacb/parkCar/batchImport",
      activeName: "first",
      parkTypeAll: [],
      workStateAll: [],
      pName: "",
      index: 0,
      total: 0,
      pageSize: 15,
      pageNum: 1,
      loading: false,
      detailFlag: false,
      areaList: [],
      parkList: [],
      tenantList: [],
      rsTypeList: {},
      plateNumber: "",
      tableCols: [
        {
          prop: "plateNumber",
          label: this.$t("list.plate_number"),
          width: "",
        },
        {
          prop: "parkCarGroupName",
          label: this.$t("list.Vehicle_group"),
          width: "",
        },
        {
          prop: "groupType",
          label: this.$t("list.Vehicle_group_type"),
          width: "",
          formatter: (row, column, cellValue, index) => {
            switch (cellValue) {
              case 1:
                return "免费车";
              case 2:
                return "临时车";
              case 3:
                return "军警车";
              case 4:
                return "月租车";
              case 12:
                return "黑名单车";
              case 11:
                return "白名单车";
              default:
                return "未知类型";
            }
          },
        },
        {
          prop: "carOwner",
          label: this.$t("list.Car_owner_name"),
          width: "",
        },
        {
          prop: "mobile",
          label: this.$t("list.phone_number"),
          width: "",
        },
        {
          prop: "effectiveStartDate",
          label: "开始日期",
          width: "",
        },
        {
          prop: "effectiveEndDate",
          label: "结束日期",
          width: "",
        },
        {
          prop: "effectiveState",
          label: "生效状态",
          width: "",
          formatter: (row, column, cellValue, index) => {
            switch (cellValue) {
              case 1:
                return "未生效 ";
              case 2:
                return "生效中";
              case 3:
                return "已过期";
              default:
                return "未生效";
            }
          },
        },
        {
          prop: "state",
          label: this.$t("list.state"),
          width: "",
          formatter: (row, column, cellValue, index) => {
            switch (cellValue) {
              case 1:
                return "启用";
              case 2:
                return "禁用";
              default:
                return "未知";
            }
          },
        },
      ],
      tableData: [],
      formInline: {
        parkCarGroupId: "",
        groupType: "",
        carId: "",
        carOwner: "",
        mobile: "",
        state: "",
        carGroup: "",
        effectiveState: "",
      },
      carGroup: [],
      carGroupType: [
        {
          desc: "免费车",
          code: 1,
        },
        {
          desc: "临时车",
          code: 2,
        },
        {
          desc: "军警车",
          code: 3,
        },
        {
          desc: "月租车",
          code: 4,
        },
        {
          desc: "黑名单车",
          code: 5,
        },
        {
          desc: "白名单车",
          code: 6,
        },
      ],
    };
  },
  filters: {
    stateFilter(state) {
      return state == 1 ? "同步成功" : "同步失败";
    },
    colorFilter(state) {
      return state == 1 ? "color_1" : "color_2";
    },
  },
  methods: {
    handleSelect(item) {
      this.formInline.carId = item.carId;
    },
    querySearchAsync(queryString, cb) {
      this.formInline.carId = "";
      queryString = queryString.replace(/\s/g, "");
      if (queryString.length < 3) {
        this.formInline.carId = "";
        cb([]);
        return;
      }
      this.$axios
        .get("/acb/2.0/parkRecord/plateNumber/list", {
          data: {
            part: queryString,
            size: 100,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            res.value.forEach((v) => {
              v.value = v.plateNumber;
            });
            cb(res.value);
          }
        });
    },
    // 同步
    sync() {
      this.$axios
        .post("/acb/2.0/bacb/parkCar/sync/" + this.$route.query.parkId, {
          data: {
            parkId: this.$route.query.parkId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.$alert(res.value);
            this.searchData();
          } else {
            this.$alert(res.desc);
          }
        });
    },
    getGroupNameList(state) {
      this.loading = true;
      this.$axios
        .get("/acb/2.0/bacb/parkCarGroup/parkCarGroupNameList", {
          data: {
            parkId: this.$route.query.parkId,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.carGroup = res.value.map((item) => {
              item.desc = item.parkCarGroupName;
              item.code = item.parkCarGroupId;
              return item;
            });
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    chargeStatus(row, state) {
      // this.loading = true;
      // let self = this;
      this.$axios
        .post("/acb/2.0/bacb/parkCar/updateStatus/" + row.parkCarId, {
          data: {
            parkCarId: row.parkCarId,
            state: state,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            if (res.value) {
              // this.detailFlag = false;
              this.searchData();
            }
          } else {
            // self.loading = false;
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    // 上传成功
    success() {},
    addBerth() {
      this.$refs.addBerth.open();
    },
    deleteData({ parkCarId }) {
      this.$confirm("确认删除车辆吗？", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .post("/acb/2.0/bacb/parkCar/delete/" + parkCarId)
            .then((res) => {
              this.loading = false;
              if (res.state == 0) {
                if (res.value) {
                  this.detailFlag = false;
                  this.searchData();
                }
              } else {
                this.loading = false;
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              }
            });
        })
        .catch(() => {
          this.loading = false;
          this.$message({
            type: "info",
            message: "已取消操作！",
          });
        });
    },
    handleCommand(cmd, data) {
      if (cmd == "a") {
        this.chargeStatus(data, 1);
      }
      if (cmd == "d") {
        this.chargeStatus(data, 2);
      }
      if (cmd == "b") {
        // this.$refs.editBerth.getDetail(data);
        this.$refs.editBerth.open(data);
      }
      if (cmd == "c") {
        this.deleteData(data);
      }
    },
    openMuli() {
      this.$refs.upload.clearFiles();
      this.$refs.upload.open();
    },
    // 分页处理
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    showLog(data) {
      if (data && data.length === 2) {
        let startTime = dateFormat(data[0], "yyyy-MM-dd HH:mm:ss");
        let endTime = dateFormat(data[1], "yyyy-MM-dd HH:mm:ss");
        this.formInline.startTime = startTime;
        this.formInline.endTime = endTime;
      } else {
        this.formInline.startTime = "";
        this.formInline.endTime = "";
      }
    },
    // 搜索
    searchData() {
      if (this.plateNumber && this.plateNumber.length >= 3) {
      } else {
        this.formInline.carId = "";
      }
      this.tableData = [];
      this.loading = true;
      let url = `/acb/2.0/bacb/parkCar/list`;
      this.$axios
        .get(url, {
          data: {
            parkId: this.$route.query.parkId,
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            ...this.formInline,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.tableData = res.value.list;
            // this.tableData = res.value.list;
            this.total = res.value.total * 1;
          } else {
            this.loading = false;
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    init() {
      this.getGroupNameList();
      this.searchData();
      this.uploadData = { parkId: this.$route.query.parkId };
    },
  },
  beforeDestroy() {
    // sessionStorage.searchData = JSON.stringify(this.formInline);
    // sessionStorage.searchFlag = 1;
  },
  components: {
    addBerth,
    uploadFile,
    editBerth,
  },
  created() {
    this.getGroupNameList();
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  height: 35px;
}

.content {
  background: #FFFFFF;
  overflow: hidden;

  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;

    .export {
      font-size: 12px;

      .iconfont {
        margin-right: 0;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.el-dropdown-link {
  cursor: pointer;
}

.color_1 {
  color: #333333;
}

.color_2 {
  color: #D9001B;
}
</style>
