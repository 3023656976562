<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <template v-if="$route.path != '/secondParkConfig'">
          <el-tab-pane label="车辆组管理" name="first">
            <carGroup-list
              :authority="
                authority.tabs[details.parkAccessType == 3 ? 'lbCarsGroupTab' : 'carsGroupTab']
              "
              :details="details"
              ref="carGroup"
            ></carGroup-list>
          </el-tab-pane>
          <el-tab-pane label="车辆管理" name="second">
            <car-list
              :authority="authority.tabs[details.parkAccessType == 3 ? 'lbCarsTab' : 'carsTab']"
              :details="details"
              ref="car"
            ></car-list>
          </el-tab-pane>
          <el-tab-pane v-if="0" label="错峰车辆管理" name="third">
            <stagerCar-list
              :authority="authority.tabs[details.parkAccessType == 3 ? 'lbStag' : 'stag']"
              :details="details"
              ref="stagerCar"
            ></stagerCar-list>
          </el-tab-pane>
          <el-tab-pane v-if="0" label="黑名单管理" name="four">
            <blacklist-Management
              :authority="
                authority.tabs[details.parkAccessType == 3 ? 'lbBlackPlateTab' : 'blackPlateTab']
              "
              :details="details"
              ref="black"
            ></blacklist-Management>
          </el-tab-pane>
        </template>
        <el-tab-pane v-if="0" label="白名单管理" name="five">
          <whitelist-Management
            :authority="
              authority.tabs[details.parkAccessType == 3 ? 'lbWhitePlateTab' : 'whitePlateTab']
            "
            :details="details"
            ref="white"
          ></whitelist-Management>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import carGroupList from "./CarGroupList";
import carList from "./CarList";
import blacklistManagement from "./BlacklistManagement";
import whitelistManagement from "./WhitelistManagement";
import stagerCarList from "./StagerCarList";

export default {
  name: "hello",
  props: ["authority", "details"],
  data() {
    return {
      activeName: this.$route.path != "/secondParkConfig" ? "first" : "five",
    };
  },
  // inject: ['parkId'],
  methods: {
    handleClick(tab, event) {
      switch (tab.name) {
        case "first":
          this.$refs.carGroup.init();
          break;
        case "second":
          this.$refs.car.init();
          break;
        case "third":
          this.$refs.stagerCar.init();
          break;
        case "four":
          this.$refs.black.init();
          break;
        case "five":
          this.$refs.white.init();
          break;
        default:
      }
    },
    init() {
      this.$route.path != "/secondParkConfig"
        ? this.$refs.carGroup.init()
        : this.$refs.white.init();
    },
  },
  beforeDestroy() {},
  components: {
    carGroupList,
    carList,
    blacklistManagement,
    whitelistManagement,
    stagerCarList,
  },
  created() {},
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb
  height 35px
.content

  overflow hidden
  .pagerWrapper
    text-align right
    margin-top 28px
    font-size 12px
    .export
      font-size 12px
      .iconfont
        margin-right 0
.detail
  width 61.4%
  height 82.9%
  position absolute
  top 8.6%
  left 20.1%
  background #FFFFFF
  box-shadow 0 0 4px 1px rgba(128, 145, 165, 0.3)
  border-radius 3px
  z-index 1000
  box-sizing border-box
  padding 20px 0
  .title
    position absolute
    background #3F4A56
    border-radius 3px 3px 0 0
    color #fff
    width 100%
    top 0
    left 0
    height 40px
    line-height 40px
    font-size 14px
    padding 0 12px
    box-sizing inherit
    z-index 100
    .el-icon-close
      float right
      margin-top 14px
  .info
    margin-top 20px
    padding 2.1%
    height 100%
    overflow auto
    box-sizing inherit
.mask
  background rgba(49, 53, 65, 0.6)
  position fixed
  top 0
  bottom 0
  left 0
  right 0
  z-index 999
.el-dropdown-link
  cursor pointer
</style>
