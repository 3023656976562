var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "编辑车辆",
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            width: "680px",
            "show-close": false,
            "custom-class": "dialog",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticStyle: { padding: "0" },
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { type: "flex", justify: "center" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "车牌号:", prop: "plateNumber" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入车牌号",
                              disabled: true,
                            },
                            model: {
                              value: _vm.form.plateNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "plateNumber", $$v)
                              },
                              expression: "form.plateNumber",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "车辆组:", prop: "parkCarGroupId" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择", disabled: true },
                              model: {
                                value: _vm.form.parkCarGroupId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "parkCarGroupId", $$v)
                                },
                                expression: "form.parkCarGroupId",
                              },
                            },
                            _vm._l(_vm.carGroup, function (value) {
                              return _c("el-option", {
                                key: value.code,
                                attrs: { label: value.desc, value: value.code },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "手机号:", prop: "mobile" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入手机号" },
                            model: {
                              value: _vm.form.mobile,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "mobile", $$v)
                              },
                              expression: "form.mobile",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "车牌颜色:", prop: "plateColor" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择", disabled: true },
                              model: {
                                value: _vm.form.plateColor,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "plateColor", $$v)
                                },
                                expression: "form.plateColor",
                              },
                            },
                            _vm._l(_vm.plateColorData, function (value) {
                              return _c("el-option", {
                                key: value.code,
                                attrs: { label: value.desc, value: value.code },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "状态:", prop: "state" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.form.state,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "state", $$v)
                                },
                                expression: "form.state",
                              },
                            },
                            [
                              _c(
                                "el-option",
                                { attrs: { value: 1, label: "启用" } },
                                [_vm._v("启用")]
                              ),
                              _c(
                                "el-option",
                                { attrs: { value: 2, label: "禁用" } },
                                [_vm._v("禁用")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "车主姓名:", prop: "carOwner" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入车主姓名" },
                            model: {
                              value: _vm.form.carOwner,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "carOwner", $$v)
                              },
                              expression: "form.carOwner",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "生效日期", required: "" } },
                [
                  _c(
                    "el-row",
                    { attrs: { type: "flex" } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "effectiveStartDate" } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "160px" },
                                attrs: {
                                  type: "date",
                                  "value-format": "yyyy-MM-dd",
                                  placeholder: "开始时间",
                                },
                                model: {
                                  value: _vm.form.effectiveStartDate,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "effectiveStartDate",
                                      $$v
                                    )
                                  },
                                  expression: "form.effectiveStartDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("el-col", { attrs: { span: 1 } }, [_vm._v("－")]),
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "effectiveEndDate" } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "160px" },
                                attrs: {
                                  type: "date",
                                  "value-format": "yyyy-MM-dd",
                                  placeholder: "结束时间",
                                },
                                model: {
                                  value: _vm.form.effectiveEndDate,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "effectiveEndDate", $$v)
                                  },
                                  expression: "form.effectiveEndDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注:" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "354px" },
                    attrs: {
                      type: "textarea",
                      placeholder: "",
                      "auto-complete": "off",
                      maxlength: "200",
                      rows: 4,
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.form.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "description", $$v)
                      },
                      expression: "form.description",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v(_vm._s(_vm.$t("button.preservation")))]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }