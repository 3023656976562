<template>
  <div>
    <el-dialog
      title="添加车辆组"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="500px"
      :show-close="false"
      custom-class="dialog"
    >
      <el-form ref="form" :model="formInline" :rules="rules" label-width="120px" style="padding:0">
        <el-form-item label="车辆组名称" prop="parkCarGroupName">
          <el-input v-model="formInline.parkCarGroupName" class="formWidth" placeholder="请填写车辆组名称" maxlength="15"></el-input>
        </el-form-item>
        <el-form-item :label="$t('searchModule.Vehicle_type')" prop="groupType">
          <el-select v-model="formInline.groupType" placeholder="请选择">
            <el-option :value="1" label="免费车">免费车</el-option>
            <el-option :value="2" label="临时车">临时车</el-option>
            <el-option :value="3" label="军警车">军警车</el-option>
            <el-option :value="4" label="月租车" :disabled="true">月租车</el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('searchModule.state')" prop="state">
          <el-select v-model="formInline.state" placeholder="请选择">
            <el-option :value="2" label="禁用">禁用</el-option>
            <el-option :value="1" label="启用">启用</el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('searchModule.remarks')">
          <el-input
            v-model="formInline.description"
            type="textarea"
            placeholder
            auto-complete="off"
            maxlength="20"
            :rows="4"
            show-word-limit
            style="width:330px"
          />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="save">{{ $t('button.preservation') }}</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      parkId: "",
      dialogVisible: false,
      formInline: {
        parkCarGroupName: "",
        groupType: "",
        state: "",
        description: "",
      },
      rules: {
        parkCarGroupName: [
          {
            required: true,
            message: "请填写车辆组名称",
            trigger: "blur",
          },
        ],
        groupType: [
          {
            required: true,
            message: "请选择车辆类型",
            trigger: "change",
          },
        ],
        state: [
          {
            required: true,
            message: "请选择状态",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    open(parkId) {
      this.$refs.form && this.$refs.form.resetFields();
      this.dialogVisible = true;
      this.parkId = parkId;
    },
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$axios
            .post("/acb/2.0/bacb/parkCarGroup/add", {
              data: {
                parkId: this.parkId,
                ...this.formInline,
              },
            })
            .then((res) => {
              if (res.state == 0) {
                if (res.value) {
                  this.$emit("searchData");
                  this.dialogVisible = false;
                }
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              }
            });
        }
      });
    },
  },
  created() {},
};
</script>
<style>
.formWidth {
  width: 221.5px;
}

</style>

